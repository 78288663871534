.pageTitle {
  margin-bottom: 28px;
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-bolder);
  color: var(--secondary-color-5);

  &.hasDescription {
    margin-bottom: 20px;
  }

  @media (--min-width-large) {
    margin-top: 28px;
  }
}

.pageTitleImage {
  height: 23px;
  width: 170px;
  margin-top: 8px;
  margin-bottom: 28px;

  @media (--min-width-small) {
    margin-top: 0;
    width: 233px;
    height: 31px;
  }
}

.description {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 800;
  max-width: 700px;
  line-height: 28px;
}
